import { ReactNode } from 'react'

interface IOfferProps {
  children?: ReactNode
  className?: string
  font?: string
  changeClassName?: boolean
}

const Offer: React.FC<IOfferProps> = ({
  children,
  className = '',
  font = 'Gotham-Medium',
  changeClassName = false,
}) => {
  return (
    <a
      style={{ fontFamily: font }}
      href={`//go.betvera.info/?_lp=1&${window.location.search.substring(1)}`}
      className={`${!changeClassName && 'flex flex-1 gap-3 items-center font-black'} ${className}`}
    >
      {children}
    </a>
  )
}

export default Offer
