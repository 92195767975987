import React from "react";
import whiteLogo from "../assets/white-logo.svg";
import headerImage from "../assets/modal/header-image.png";
import tagAdult from "../assets/tag-adult.png";
import Offer from "../Offer";
import "../modal.css";
import styled from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999999;
`;

const Modal: React.FC = () => {
  return (
    <ModalOverlay>
      <div className="container-all">
        <div className="container-header-image">
          <img src="/assets/modal/roof.png" alt="roof" />
          <div className="triangle"></div>
          <div
            className="header-image"
            style={{
              background: `url(${headerImage})`,
              backgroundSize: "cover",
            }}
          ></div>
        </div>
        <div className="white-bg">
          <div className="gradient-bg">
            <div className="container-title">
              <div className="title">GIROS GRÁTIS</div>
              <div className="subtitle">ESPERAM POR VOCÊ</div>
            </div>
            <img
              src="/assets/modal/mega-logo-white.png"
              alt="mega-logo-white"
            />
            <div className="container-desc-phrase">
              <h2>deposite</h2>
              <h2>
                giros no <br />
                fortune tiger
              </h2>
            </div>
            <div className="container-offer">
              <Offer className="offer">
                <div className="prize">
                  <div className="total">
                    <p>R$20</p>
                  </div>
                  <div className="round">
                    <p>03</p>
                  </div>
                </div>
              </Offer>
              <Offer className="offer">
                <div className="prize hot">
                  <div className="total">
                    <p>R$50</p>
                  </div>
                  <div className="round">
                    <p>06</p>
                  </div>
                </div>
              </Offer>
              <Offer className="offer">
                <div className="prize">
                  <div className="total">
                    <p>R$100</p>
                  </div>
                  <div className="round">
                    <p>15</p>
                  </div>
                </div>
              </Offer>
              <Offer className="offer">
                <div className="prize hot">
                  <div className="total">
                    <p>R$250</p>
                  </div>
                  <div className="round">
                    <p>25</p>
                  </div>
                </div>
              </Offer>
              <Offer className="offer">
                <div className="prize">
                  <div className="total">
                    <p>R$500</p>
                  </div>
                  <div className="round">
                    <p>50</p>
                  </div>
                </div>
              </Offer>
              <Offer className="offer">
                <div className="prize hot">
                  <div className="total">
                    <p>R$1000</p>
                  </div>
                  <div className="round">
                    <p>80</p>
                  </div>
                </div>
              </Offer>
            </div>
            <div className="footer">
              <Offer className="button">
                <span>resgatar agora</span>
              </Offer>
            </div>
            <div className="container-tag">
              <img src="/assets/modal/tag-adult.png" alt="tag-adult" />
            </div>
          </div>
        </div>
      </div>
    </ModalOverlay>
  );
};

export default Modal;
